

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faGithub, faLinkedin, faXTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'



const Social = () => {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '300px',
            marginTop: '30px',
            gap: "auto",
            opacity: 0.3,
            color: 'white',
        }}>

            <button onClick={() => { window.open('https://twitter.com/enscngvn') }}>

                <FontAwesomeIcon icon={faXTwitter} size='2x' />
            </button>
            <button onClick={() => { window.open('https://www.instagram.com/enscngvn') }}>
                <FontAwesomeIcon icon={faInstagram} size='2x' />
            </button>
            <button onClick={() => { window.open('https://www.linkedin.com/in/guvenenes/') }}>
                <FontAwesomeIcon icon={faLinkedin} size='2x' />
            </button>
            <button onClick={() => { window.open('https://www.youtube.com/@enscngvn') }}>
                <FontAwesomeIcon icon={faYoutube} size='2x' />
            </button>
            <button onClick={() => { window.open('https://github.com/enescanguven/') }}>
                <FontAwesomeIcon icon={faGithub} size='2x' />
            </button>

        </div>
    )
}

export default Social