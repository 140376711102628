import './App.css';
import NavBar from './NavBar';
import Chat from './Chat';
import Social from './Social';


function App() {

  return (

    <>
      <div className="custom-bg w-full h-screen">
        <NavBar />
        <div className="flex justify-center items-center flex-col">
          <Chat />
        <Social/>
        </div>
      </div>
    </>
  );
}

export default App;
