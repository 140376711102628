import './chat.css';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'


const Chat = () => {

    const messagePlaceholder = { "TR": "Mesajınızı buraya yazın...", "EN": "Type your message here..." }
    const disclaimer = { "TR": "Bu bot benle ilgili yanlış cevaplar verebilir. Bu nedenle cevapların doğruluğunu bana sorarak teyit edebilirsin :)", "EN": "This bot may give wrong answers about me. Therefore, you can confirm the accuracy of the answers by asking me :)" }
    const firstMessage = { "TR": "Merhaba, Ben Enes Can! bana istediğini sor!", "EN": "Hello, I'm Enes Can! Ask me anything!" }

    const [inputValue, setInputValue] = useState('');
    const [language, setLanguage] = useState("EN");
    const [messages, setMessages] = useState<any[]>([]);
    const { REACT_APP_API_URL } = process.env;

    const getResponse = async (message: any) => {
        const response = await fetch(`${REACT_APP_API_URL}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'role': 'bot',
                'message': message,
                'language': language
            }),
        });
        const data = await response.json();
        return data;
    }

    const sendMessage = async () => {

        if (inputValue.trim() !== '') {
            setMessages((prevMessages) => [...prevMessages,
            {
                text: inputValue,
                sender: 'user',
            },
            {
                text: <div className='loading'>...</div>,
                sender: 'bot',
            }
            ]);
            setInputValue('');

            const response = await getResponse(inputValue);

            setMessages([
                ...messages,
                {
                    text: inputValue,
                    sender: 'user',
                },
                {
                    text: <div>{response.body}</div>,
                    sender: 'bot',
                }
            ]);
        }
    }

    const handleKeyPress = (event: any) => {
        if (event.key === 'Enter') {
            sendMessage();
        }
    }

    return (
        <div className='chat-gen'>
            <div className="chat-header">
            </div>
            <div>
                <div className="chat-container">


                    <div className='bot-message'>
                        {language === "TR" ? firstMessage["TR"] : firstMessage["EN"]}
                    </div>
                    {messages.map((message, index) => (

                        <div
                            key={index}
                            className={message.sender === 'user' ? 'user-message' : 'bot-message'}>

                            {message.text}

                        </div>
                    ))}

                </div>
                <div className="chat-in">
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '10px'
                    }}>

                    </div>
                    <button className="send-btn"
                        onClick={() => setLanguage(language === "TR" ? "EN" : "TR")}
                    >
                        {language === "TR" ? "TR" : "EN"}
                    </button>
                    <input type="text" className="chat-input" placeholder={language === "TR" ? messagePlaceholder["TR"] : messagePlaceholder["EN"]}
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        onKeyPress={handleKeyPress}

                    />
                    <button className="send-btn"
                        onClick={sendMessage}
                    >
                        <FontAwesomeIcon icon={faPaperPlane} />

                    </button>
                </div>

                <p className="disclaimer mt-4">{language === "TR" ? disclaimer["TR"] : disclaimer["EN"]} enescanguven@gmail.com</p>
            </div>
        </div>
    );
}

export default Chat;
